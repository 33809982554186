import React from 'react';
import { Box, Container, Typography } from "@mui/material";
import { useTranslation } from 'react-i18next';
import configuration from 'configuration';

const startWith = 2024;
function TermsPage() {
  const { t, i18n } = useTranslation('userAgreement');
  return (
    <Container>
      <Box component='section' sx={{ py: 4 }} dangerouslySetInnerHTML={{ __html: t('text', { email: configuration.mail }) }}>

      </Box>
    </Container>
  );
}

export default TermsPage;
